import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_LOGIN_API_USERNAME,
  password: process.env.VUE_APP_LOGIN_API_PASSWORD
};

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(`(?:^|; )${name}=([^;]+)`); // is `^name` or `; name`
    var value = re.exec(document.cookie);
    return (value != null) ? decodeURIComponent(value[1]) : null;
  })('csrftoken');
}

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const PERSONAL_DATA_URL = process.env.VUE_APP_PERSONAL_DATA_API_PATH

const personalDataAPI = {
  async postPersonalDataDemand() {
    const url = new URL(path.join(`${i18n.locale}${PERSONAL_DATA_URL}`, 'demands/'), DOMAIN);
    const response = await axios.post(
      url,
      null,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getPersonalDataDemands() {
    const url = new URL(path.join(`${i18n.locale}${PERSONAL_DATA_URL}`, 'demands/'), DOMAIN);
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  // async downloadPersonalData(url) {
  //   const response = await axios.get(
  //     url,
  //     {
  //       responseType: 'blob',
  //       ...DEV_AUTH && { auth: AUTH }
  //     }
  //   );
  //   if (response.status === 200) {
  //     return response.data;
  //   }
  //   return false;
  // }
}

export default personalDataAPI;
